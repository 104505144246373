
.project {
    width: 100%;
    /* height: calc(100vh - 100px); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .projectItem{
    margin: auto;
    margin-top: 10px;
  }
  
  .project h1 {
    margin-top: 30px;
    color: #13a89e;
    font-size: 35px;
  }
  /* hier moet media query bij komen */
  .imageDiv{
    height: 330px;
    width: 330px;
    display: flex;
    justify-content: center;
    
  }
  .smallScreenImage{
    margin: auto;
  }
  .project img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  
  .project p {
    font-size: 1rem;
    max-width: 1000px;
    padding: 15px;
  }
  
  .project svg {
    font-size: 60px;
    color: #13a89e;
  }
  .carouselDiv{
    display: none;
  }
  .smallScreenImage{
    display: block;
  }
  .contextDiv{
    margin-top: 30px;
  }
  body{
    text-align: center;
  }

  ul{
    text-align: left;
  }

  @media only screen and (min-width: 600px){
    .imageDiv{
      height:auto;
      width: 600px;
    }
    .carouselDiv{
      display: block;
    }
    .smallScreenImage{
      display: none;
    }
      
    
  }

  @media only screen and (min-width: 1000px){
    .imageDiv{
      height:auto;
      width: 800px;
    }
    p{
      margin: 0px 150px;
    }

  }