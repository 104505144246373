.stageDiv p{
  margin-bottom: 50px !important;
}
.stageDiv{
    margin: 50px 50px;
}
.documents{
  text-align: center;
}
.logo{
  border: none;

}
.stageProjects{
  margin: 30px 0px;
}
.projectList2 {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
}
.projectItem{
  margin-right: 20px;
}

/* @media only screen and (min-width: 1000px){

    p{
      margin: 0px 150px;
    }

  } */

@media only screen and (max-width: 1600px) {
  .projectList2 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  .projectItem {
    width: 250px;
    height: 270px;
  }
  .projectItem h1{
    font-size: 20px;
  }
}


@media only screen and (max-width: 1300px) {
  .projectList2 {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  .projectItem {
    width: 250px;
    height: 270px;
  }
  .projectItem h1{
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .projectList2 {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList2 {
    width: 100%;
  }
  .projectItem {
    width: 300px;
    height: 300px;
  }
}