@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');
.App {
  text-align: center;
  
}
html{
  margin:0px;
  padding:0px;
}
body{
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
}

h2{
  text-align: center;
}
.introduction{
  text-align: center;
}
.projectList{
  margin: auto;
}











