.footer {
    width: 100%;
    height: 200px;
    background:#13a89e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 150px;
  }

  .cvButtonFooter{
    /* background-color: white; */
    border: none;
    border-radius: 5px;
    color: white !important;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 40px;
    font-weight: bold;
  }

  .cvButtonFooter:hover{
    color: black !important;
  }
  
  .IconLinks a svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  .IconLinks a svg:hover {
    color: black;
  }
  
  .footer p {
    color: white;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }